<script setup lang="ts">
import MarketingHeader from "@/layouts/partials/marketing/MarketingHeader.vue";
import MarketingFooter from "@/layouts/partials/marketing/MarketingFooter.vue";
import DownloadAppModal from "@/components/marketing/DownloadAppModal.vue";
import { useRootStore } from "@/store/root";

const rootStore = useRootStore();
const { downloadAppModalActive } = storeToRefs(rootStore);
</script>

<template>
  <div class="bg-decoration">
    <MarketingHeader />

    <main class="px-6 lg:px-8 py-20 lg:py-32">
      <slot />
    </main>

    <MarketingFooter />
    <ScrollToTop />
    <DownloadAppModal :show="downloadAppModalActive" @close="rootStore.toggleDownloadAppModal(false)" />
  </div>
</template>
