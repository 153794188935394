<script setup lang="ts">
import AppDownloadLinks from "@/components/marketing/AppDownloadLinks.vue";

interface Props {
  show: boolean;
}

interface Emits {
  (e: "close"): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();
</script>

<template>
  <Modal :show="show" @close="$emit('close')" max-width="max-w-full sm:max-w-fit p-7 sm:p-8">
    <p class="text-xl font-semibold leading-6 text-center text-dark-gray mb-5">Download the app</p>
    <AppDownloadLinks @close="$emit('close')" />
  </Modal>
</template>
