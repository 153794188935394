<script setup lang="ts">
import ScrollTop from "primevue/scrolltop";

interface Props {
  target?: string;
}

const props = defineProps<Props>();
</script>

<template>
  <ScrollTop
    :pt="{
      root: {
        class: [
          'fixed bottom-10 right-10 flex items-center justify-center',
          'ml-auto',
          {
            '!bg-primary hover:bg-primary-dark text-white rounded-md h-8 w-8  border border-white  shadow-sm':
              props.target === 'parent',
            '!bg-primary hover:bg-primary-dark h-10 w-10 rounded-full text-white border border-white shadow-sm':
              props.target !== 'parent',
          },
        ],
      },
      transition: {
        enterFromClass: 'opacity-0',
        enterActiveClass: 'transition-opacity duration-150',
        leaveActiveClass: 'transition-opacity duration-150',
        leaveToClass: 'opacity-0',
      },
    }"
  />
</template>
